<template>
  <div>
    <x-table
      title="通知记录"
      :card="true"
      :options="options"
      @search="searchHandle"
      @rowRead="rowRead">
      <template slot="topicName" slot-scope="{data}">
        <span class="badge badge-primary"> {{data}}</span>
      </template>
      <template slot="subscribeId" slot-scope="{data}">
        <span class="badge badge-primary"> {{ allNotifications[data] ? allNotifications[data].subscribeName : '' }}</span>
      </template>
    </x-table>
  </div>
</template>
<script>
import XTable from '@core/components/cx/table/XTable.vue'
import {
  query, read,
} from '@/api/system/usercenter/notification-record'
import {
  queryAllNotify,
} from '@/api/system/usercenter/notification'

export default {
  components: {
    XTable,
  },
  data() {
    return {
      providers: [],
      fileList: [],
      produts: [],
      allNotifications: {},
      options: {
        formType: 'sidebar',
        hideStyleSwitch: true,
        actions: [
          { action: 'read', tip: '标为已读', icon: 'CheckIcon' },
        ],
        actionFilter: (action, row) => {
          if (row.state.value === 'unread') {
            return true
          }
          return action === this.$x.biz.FormType.VIEW
        },
        columns: [{
          label: '消息类型',
          labelShow: true,
          prop: 'topicName',
          searchShow: false,
        }, {
          label: '订阅类型',
          labelShow: true,
          prop: 'subscribeId',
          searchShow: false,
        }, {
          label: '消息内容',
          labelShow: true,
          prop: 'message',
          searchShow: false,
        }, {
          label: '状态',
          labelShow: true,
          prop: 'state',
          type: 'select',
          dictData: [{ value: 'unread', text: '未读' }, { value: 'read', text: '已读' }],
          rowSelect: true,
          selectVariant: prop => {
            if (prop.value === 'unread') {
              return 'danger'
            }
            return 'success'
          },
          props: { label: 'text', value: 'value' },
          editShow: false,
          addShow: false,
          viewShow: false,
        }, {
          label: '通知时间',
          labelShow: true,
          prop: 'notifyTime',
          type: 'datetimerange',
          searchShow: true,
        },
        ],
      },
    }
  },
  created() {
    queryAllNotify().then(resp => {
      resp.data.result.data.forEach(item => {
        this.allNotifications[item.id] = item
      })
    })
  },
  methods: {
    searchHandle(page, params, done) {
      query(page.pageIndex - 1, page.pageSize, params).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    rowRead(data, done) {
      read(data.id).then(() => {
        done()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
</style>
