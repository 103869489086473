import request from '@/auth/jwt/useJwt'
// 获取通知订阅列表
export function query(pageIndex, pageSize, params) {
  let requestUrl = `notifications/_query?pageIndex=${pageIndex}&pageSize=${pageSize}&sorts%5B0%5D.name=notifyTime&sorts%5B0%5D.order=desc`
  if (params) {
    let index = 0
    if (params.state) {
      requestUrl += `&terms[${index}].column=state&terms[${index}].value=${params.state}`
      index++
    }
    if (params.notifyTime) {
      requestUrl += `&terms[${index}].column=notifyTime$btw&terms[${index}].value=${params.notifyTime[0]},${params.notifyTime[1]}`
    }
  }
  return request.axiosIns({
    url: encodeURI(requestUrl),
    method: 'get',
  })
}

export function read(id) {
  return request.axiosIns({
    url: `notifications/${id}/read`,
    method: 'get',
  })
}
